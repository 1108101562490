import '../scss/custom.scss';

$(document).ready(function()
{
    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }

        return null;
    }

    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    if (!getCookie('cookie_consent')) {
        $('.cookie-consent').show();
    }

    $('.cookie-consent .close-alert ').on('click', function (e) {
        e.preventDefault();
        setCookie('cookie_consent', 1);
    });

    //Add special class to ol elements.
    $('.body').find('ol').addClass('list list-ordened');

});